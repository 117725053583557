
    <template>
        <div>

          <modal name="bookmakerpromo" height="180" width="200"  @before-open="beforeOpen" :shiftY=ypos :shiftX=xpos >
                <form>
                   
                        <template v-if="promodetailloaded">
                            <div v-on:mouseleave="mouseLeave('bookmakerpromo')" :style="{backgroundColor: advertpop[0].BackColour}">
                            <singleadverts :row="advertpop[0]" :backcolour="advertpop[0].BackColour" ></singleadverts>

                            <!-- <div class="promocontent" :style="{backgroundColor: advertpop[0].BackColour}">

                                <div class="grid-x">
                                   
                                    <div class="cell small-3"><span :class="'b' + advertpop[0].BkmName"></span></div><div class="cell small-7"><a :href="advertpop[0].BanURL" style="color: white; text-align:center"><h4 v-html="advertpop[0].BanTitle "></h4></a></div><div class="cell small-2"><div class="button" v-on:click="hidePopUp('bookmakerpromo',false)">X</div></div>

                                    <div class="cell small-12"><a :href="advertpop[0].BanURL" style="color: white; text-align:center" v-html="advertpop[0].BanDesc"> </a></div>
                                    <div class="cell small-12"><a :href="advertpop[0].BanURL" style="color: white; text-align:center"><small>T and Cs apply</small></a></div>

                                </div>

                                <div class="grid-x">
                                    <div class="cell small-9"><img src="/assets/images/18plus.png" /><img src="/assets/images/begambleawareorg_white_png.png" /></div><div class="cell small-3"><a class="button warning" :href="'/bookmakerloader.html?bkm=' + advertpop[0].BkmName" target="_blank">Claim</a></div>
                                </div>
                                
                            </div> -->
                        </div>
                        </template>
                    
                </form>
            </modal>

            <modal name="allpuntersbookmakerpromo"  height="200" :width="200" @before-open="beforeOpen" :shiftY=ypos :shiftX=xpos  >
                <form>
                    <div v-on:mouseleave="mouseLeave('allpuntersbookmakerpromo')">
                        <template v-if="promodetailloaded">


                            <singleadverts :row="advertpop[0]" :backcolour="advertpop[0].BackColour"></singleadverts>


                        </template>
                    </div>
                </form>
            </modal>


            <modal name="regulationarea" :adaptive="true" height="auto" :clickToClose="false"  >
                <div >
                    <!-- <h3 style="display:inline-block">Gaming Licenses</h3>
                    <div style="display:inline-block;float:right" class="button" v-on:click="hidePopUp('regulationarea', false)">X</div> -->
                    <div class="grid-x">
                        <licenses :popup=true></licenses>
                    </div>
                
                </div>
            </modal>  


        </div>
    </template>

<script>

    import axios from 'axios';
    import licenses from '/src/components/components/licenses'
    import singleadverts from '/src/components/adverts/singleadverts.vue'

    export default {
        name: "popup",
        data: function () {
            return {

                advertpop: [],
                promodetailloaded: false,
                xpos: 0,
                ypos: 0

            }
        },
        mounted() {
            
        },
        components: {
            licenses,
            singleadverts
        },

        methods: {

            test: function () {

                location.reload(true)

            },

            // hide popups
            mouseLeave: function (val) {
             
                this.hidePopUp(val, false);
                
            },

            hidePopUp: function (val,reload) {
         

                if (reload === undefined) reload = false;

                this.$modal.hide(val);

                //update screen (reload for no?)
                if (reload === true) location.reload(true);
            },

 

            //actions before create promopopup
            beforeOpen(event) {


                //assign popup starting position
                this.xpos = event.params.mousexpos - 0.15;
                this.ypos = event.params.mouseypos;


                let params = event.params.passedparams.split(",");

                this.promodetailloaded = false;

                

                axios.get(process.env.VUE_APP_WEBAPI  + "/AdvertService.svc/AdvertPop", {

                    params: {

                        compID: params[1],
                        bkmID: params[0],
                        offertypeID: params[2],
                        eventID: params[3],
                        country:  this.$store.state.countryname
                    }

                }).then(response => {this.advertpop = JSON.parse(response.data); this.promodetailloaded = true; });

            }

        }
    }
</script>

<style>

</style>


