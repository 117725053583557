<template>
    <div v-if="licensesloaded" style="padding:10px" >

        <div style="position: relative;height:130px">

<div  >

     <h1 class="qmajorheader qalignmiddle qlargerfont" v-html="langConv('Select your country')"></h1>
     <h2 class="qsubheader qalignmiddle qsmallfontplus" v-html="langConv('This page connects you with regulated bookmakers for your region, so you only get the odds and deals you’re actually allowed to use.')"></h2>
     <div class="qalignmiddle qsmallfontplus" v-html="langConv('If you need to manually change this setting you can do so here')"></div>

</div>
</div>

        <div v-for="(row,i) in licenses" :key="i" >
            
            <div class="grid-x qsmallfontplus1">
         
                <div class="cell small-1 medium-offset-4" id="circularimage" style="display:table-cell;vertical-align:middle"><img style="margin-top:6px" class="qflagmedium" :src="'/assets/images/flags/svg/' + row.Description + '.svg'" onerror="this.onerror=null;this.src=''"/></div>
                <div class="speccol cell small-2"><input class="qpointer" style="margin-top:14px" type="checkbox" :id="row.ID" :value="row.ID" v-model="checked"></div>
                <div class="speccol cell small-5" style="margin-top:12px"><a :href="row.URL" target="_blank" class="qborderlink">{{langConv(row.Description)}}</a></div>

            </div>
         
           
        </div>
        <div style="margin-top:40px">
            <div class="grid-x text-center">
               <div class="cell small-12"><button class="button small" v-on:click="checkSomethingSelected();saveSelectedOptions()" v-html="langConv('Proceed')"></button></div>
            </div>
        </div>



 <!-- <div>checked: {{checked}} - License Bodies - {{$store.state.regbody}}</div> -->

    </div>
</template>

<script>

    export default {
        name: "licenses",
        data: function () {
            return {
                licenses: [],
                licensesloaded: false,
                checked: [] 
            }
        },
        props: {
            popup: {
                type: Boolean,
                default: false
            }
        },

        created() {

            this.checked = this.$store.state.regbody;

            this.getLicenseData();

        },
        methods: {

            closeScreen() {
                   {if (this.popup == false) { this.$router.go(-1) } else { this.$modal.hide('regulationarea'), this.$router.go(0)}}
            },
 
            getLicenseData: function () {

                //does country have a license assigned (without going to the webserver)
                //if country ID not 

                this.localJSONLoad(process.env.VUE_APP_DATA + "licensedcountry.json").then(response => {
                    this.licenses = response
                    this.licensesloaded = true;
               });

            },

            IDinList: function (val) {
                return this.$store.state.regbody.includes(val)
            },

            checkSomethingSelected(e) {

            
                this.checked = this.checked.filter(item => item !== 0)  

                //ensure that at least on check box clicked 
                if (this.checked.length==0 && this.$store.state.sb.length == 0) {
                       this.$confirm({
                        message: `At least one Licensing Country must be selected`,
                        button: {
                            yes: "OK",
                }});
                   e.preventDefault();
                       
                }

            },

            saveSelectedOptions: function(val) {

                
                //note: confirm too much information for the user 

                //warning that this will override selected bookmakers
                // this.$confirm({
                // message: `This may reset any selected bookmakers. Are you sure?`,
                // button: {
                //     no: "No",
                //     yes: "Yes",
                // },
                // callback: (confirm) => {
                // alert("arse");
                // if (confirm) {

                    


                    //     //save the settings back to vuex
                         this.$store.commit("setRegBody", this.checked); 

                    //     //set dummy IP address
                         this.$store.commit("setBespokeBookies", true);

     
                        let _this = this;

                    //     //reset the bookmakers
                         _this.setSelectedBookmakerswithCountryLicense().then(result => {

                  

                    //         //save user stuff back to back end db
                              _this.saveuser().then(resolve => {
                        
                               

                               //put in delay so box does not come up again
                               if (_this.popup == false) { _this.$router.go(-1) } 
                               else
                                 {window.setTimeout(function() { 

                                         _this.$modal.hide('regulationarea'); 
                                         _this.$router.go(0)              
                                },500)}
                            
                            
                            });

                       

                        

                        });
                     }
                },
                
                // }               
              
        }

      

</script>

<style scoped>


.speccol {
    display:table-cell;
    vertical-align:middle;
    padding-left:20px;
}

</style>